<!--
 * @Author: your name
 * @Date: 2021-10-14 15:00:42
 * @LastEditTime: 2021-10-27 15:15:57
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \mingtai-yonghu\src\view\index\index.vue
-->
<template>
  <div class="container">
    <s-header />
    <div class="main">

      <div class="tipBox" v-if="auditObj">
        <a-row>
          <a-col>{{auditObj.message}}</a-col>
          <a-col>
            <div class="desc">
              {{auditObj.data}}
            </div>
          </a-col>
          <router-link :to="auditObj.data?{name:'Reg',params:params}:'/Login'" style="font-size:14px;">
            <a-icon type="arrow-left"/><a>{{auditObj.data?'修改信息':'返回登录'}}</a>
          </router-link>
        </a-row>
        
      </div>

      
    </div>
    <s-footer />
  </div>
</template>

<script>
import sHeader from "@/view/components/FakeHeader";
import sFooter from "@/view/components/Footer";

export default {
  components: {
    sHeader,
    sFooter,
  },
  data() {
    return {
      auditObj: null,
      params: null,
    };
  },
  created() {},
  mounted() {
    this.auditObj = this.$route.query;
    this.params = this.$route.params;
  },
  methods: {
  },
};
</script>

<style lang="scss" scoped>
// 公共样式
.hide {
  display: none;
}
.show {
  display: block;
}
a {
  color: #656565;
}
.ant-row {
  text-align: center;
}
* {
  font-family: "微软雅黑";
}
.no-data{
  width: 100%;
  padding-top: 10%;
  font-size: 35px;
  color: #d7d7d7;
  text-align: center;
}
.card-title {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  display: -moz-box;
  -moz-line-clamp: 2;
  -moz-box-orient: vertical;
  word-wrap: break-word;
  word-break: break-all;
  white-space: normal;
  color: #626262;
  font-size: 13px;
  font-weight: unset;
}
.container {
  width: 100%;
  height: 100%;
  background-color: #f2f2f2;
  background-size: 100% 100%;
  overflow: hidden;
  position: relative;
  .main {
    width: 1366px;
    margin: 0 auto;
    height: calc(100% - 122px);
    box-sizing: border-box;
    padding: 20px 0px;
    display: flex;
    flex-direction: column;
    .serchBox {
      display: flex;
      position: relative;
      .serchIcon {
        height: 30px;
        position: absolute;
        left: 15px;
        top: 50%;
        margin-top: -15px;
      }
      .serchInp {
        height: 42px;
        border: 1px solid #eeeeee;
        flex: 1;
        padding-left: 55px;
        color: rgb(100, 100, 100);
        font-size: 15px;
        border: 0px;
      }

      .serchBtn {
        margin-left: 20px;
        width: 100px;
        color: white;
        border: 1px solid #063A61;
        background-color: #063A61;
        font-size: 16px;
        cursor: pointer;
      }
    }
    .typeBox {
      margin-top: 20px;
      display: flex;
      flex-wrap: wrap;
      width: 80%;
      .tagBox {
        display: flex;
        color: #999999;
        font-size: 16px;
        flex-wrap: wrap;
        div {
          text-align: center;
          cursor: pointer;
          padding: 5px 10px;
          margin: 0px 10px;
        }
        div:hover {
          background-color: #063A61;
          color: white;
        }
        .active {
          background-color: #063A61;
          color: white;
        }
      }
      .tagBox:last-child {
        margin-top: 10px;
      }
    }
    .tipBox {
      font-size: 24px;
      color: #063a61;
      width: 100%;
      height: calc(100% - 82px);
      justify-content: center;
      display: flex;
      align-items: center;
      .desc {
        margin-top:20px;
        font-size: 16px;
        color: #a2a2a2;;
      }
    }
    .listbox {
      margin-top: 20px;
      background-color: white;
      box-sizing: border-box;
      padding: 20px;
      display: flex;
      column-gap: 60px;
      row-gap: 20px;
      flex-wrap: wrap;
      height: calc(100% - 82px);
      position: relative;
      overflow: auto;
      img {
        max-height: 120px;
        object-fit: cover;
      }
      .tcount {
        position: absolute;
        top: 100px;
        color: white;
        right: 0px;
        border-top-left-radius: 5px;
        background-color: rgba(0, 0, 0, 0.5);
        box-sizing: border-box;
        padding: 1px 5px;
        font-size: 12px;
      }
      .itemtitle {
        padding: 10px;
        box-sizing: border-box;
        span {
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          cursor: pointer;
          font-size: 14px;
        }
        span:hover{
          color:#063A61;
        }
      }
      .czBox {
        display: flex;
        box-sizing: border-box;
        padding: 10px 0 0 0;
        position: absolute;
        bottom: 10px;
        width: 100%;
        div {
          color: #9ca3ab;
          flex: 1;
        }
        .countTxt {
          background-repeat: no-repeat;
          background-image: url("../../static/images/mingtai/countIcon.png");
          padding-left: 20px;
          background-position: 0% 50%;
          font-size: 12px;
          background-size: 16px 16px;
          text-align: left;
        }
        .dateTxt {
          background-repeat: no-repeat;
          background-image: url("../../static/images/mingtai/dateIcon.png");
          padding-left: 20px;
          background-position: 0% 50%;
          font-size: 12px;
          background-size: 16px 16px;
        }
      }
    }
    .footetPage {
      width: 97%;
      text-align: center;
      position: absolute;
      bottom: 0px;
      padding: 15px;
      a {
        font-size: 14px;
        padding: 0px 5px;
        border: 1px solid #cecece;
        margin: 0px 5px;
      }
      span {
        font-size: 14px; 
        color: #656565;
      }
      a:hover {
        color: #063A61;
      }
    }
  }
}
</style>